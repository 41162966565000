import React from "react";
import PrivacyPolicy from "../components/PrivacyPolicyPage/PrivacyPolicyPage";

function PrivacyPolicyPage() {
    return (
        <>
            <PrivacyPolicy/>
        </>
    );
}

export default PrivacyPolicyPage;
